.breaker {
  @apply bg-accent w-[24px] md:w-[72px] h-[1px] md:h-[2px];

  content: '';
  border: none;
  left: 0;

  &.block {
    display: block;
  }
}
