.title {
  @apply text-accent mb-2 font-poppins pb-2 md:pb-0 md:pl-[104px];
  position: relative;

  &:before {
    @apply bg-accent;
    @apply w-[24px] h-[1px] -translate-x-1/2 bottom-0 left-1/2;
    @apply md:w-[72px] md:h-[2px] md:translate-x-0 md:left-0 md:-translate-y-1/2 md:left-0 md:top-1/2;

    position: absolute;
    content: '';
  }

  &.inverted {
    @apply text-main;

    &:before {
      @apply bg-main;
    }
  }

  &.vertical {
    @apply md:pl-0 md:mb-0 md:pb-0;

    &:before {
      @apply md:hidden;
    }
  }

  &.skipMargin {
    @apply mb-0;
  }
}
